import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from "pinia";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'

import tooltip from "./directives/tooltip.js";
import "./assets/css/tooltip.css";

library.add(fas);
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fab);
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(far);
import { dom } from "@fortawesome/fontawesome-svg-core";
dom.watch();
require('@/assets/css/main.scss');

const app = createApp(App);
const _pinia = createPinia();

app.component("font-awesome-icon", FontAwesomeIcon)
app.directive("tooltip", tooltip);
//createApp(App).use(store).use(router).mount('#app')
app.use(_pinia).use(router).mount('#app')