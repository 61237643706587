import axios from "axios";
import { config } from "@/static/constants";
export const checkIp = async () => {
  const response = await axios.get(`${config.API_URL}/utilities/check-ip`);
  return response.data;
};

export const getCandidate = async (bullhorn_id) => {
  const response = await axios.get(
    `${config.API_URL}/jobseeker/show/${bullhorn_id}`
  );
  return response.data;
};

export const getS3Bucket = () =>{
  return `${config.S3_BUCKET}`;
}
