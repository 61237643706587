<script setup>
import { onMounted, ref } from "vue";
import { reactive } from "vue";
import { useRoute } from "vue-router";

//import { config } from "@/static/constants";
//import axios from "axios";
//import arrow from '@/assets/img/asl-images/arrow.png';
import gauge from "@/components/PricingGauge.vue";

//import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { useMainStore } from "@/store/";
import TopMenu from "./TopMenu.vue";
import LoaderBar from "./LoaderBar.vue";
//import ShareLink from "../components/ShareLink.vue";

const profileHeader = reactive({
  isDisplayPicture: false,
  isDisplayVoice: false,
  profilePicLink: "",
  voiceRecordingLink: ""
});
const route = useRoute();
//const router = useRouter()
//const displayPage = reactive({ data: true });
const hasData = ref(false);
const modalDisplay = ref(false);
//const DEV_API_URL = config.API_URL;
/*let pricing = "";
    let price = "";
    let pricingGuide ="";*/
const message = reactive({data:''});
const showTooltip = reactive({data:false});
let resumeText = "";
let asl_candidate = { info: {} };
let skills = {
  intermediate: [],
  advanced: []
};

let symbol = "";
let currency = "";

const store = useMainStore();
const age = ref(0);
const loadDefaults = () => {
  //console.log(store.candidateData);
  if (store.ipData) {
    currency = store.currency;
    symbol = store.symbol;
  }

  if (store.candidateData) {
    
    age.value = store.getAge;
    asl_candidate.info = store.candidateData;
    profileHeader.profilePicLink = store.getPicture.picture_link;
    profileHeader.isDisplayPicture = store.getPicture.isDisplayPicture;

    profileHeader.voiceRecordingLink = store.getVoiceRecording.voice_link;
    profileHeader.isDisplayVoice = store.getVoiceRecording.isDisplayVoice;
    //getSkills(asl_candidate.info);
    skills.intermediate = store.getSkills.intermediate;
    skills.advanced = store.getSkills.advanced;
    //getFinalWorkArray(asl_candidate.info.user_basic_info.work_history);
    setTimeout(() => {
      hasData.value = true;
    }, 3000);
  } else {
    //console.log(store.candidateData);
    window.location.href = `https://www.remotestaff.com.au/candidate-unvailable/`;
  }
};

/*store.$subscribe(() => {
  setTimeout(() => {
    
  }, 3000);
});*/

onMounted(() => {
  store.checkIp();
  if (route.params.bullhorn_id) {
    console.log(modalDisplay.value);
    store.getCandidate(route.params.bullhorn_id).then(()=>{
      loadDefaults();
    });
  }
  //init();
});

function getRates(data) {
  let result = {
    pt_hourly: "",
    ft_hourly: "",
    pt_monthly: "",
    ft_monthly: "",
    staff_monthly: "",
    work_type: "",
    staff_hourly_rates: "",
    work_status:[],
  };
  let input = data;
  if (input.hourly_rates) {
    let hourly_rates = input.hourly_rates.v2.employee_types;
    if (input.user_basic_info.work_status.length > 1) {
      result.pt_hourly = hourly_rates["part_time"][currency]["hourly_rate"];
      result.ft_hourly = hourly_rates["full_time"][currency]["hourly_rate"];
      result.pt_monthly = hourly_rates["part_time"][currency]["monthly_rate"];
      result.ft_monthly = hourly_rates["full_time"][currency]["monthly_rate"];
      for (var j = 0; j < data.user_basic_info.work_status.length; j++) {
        //workStatus.push(data.user_basic_info.work_status[i].work_status_desc);
        result.work_status.push(data.user_basic_info.work_status[j].work_status_desc);
      }
      //console.log("hey");
    } else {
      let workStatus = [];
      let type = input.user_basic_info.work_status.count > 0 ? input.user_basic_info.work_status[0].work_status_desc:" ";
      let temp_type = "full_time";

      for (var i = 0; i < data.user_basic_info.work_status.length; i++) {
        workStatus.push(data.user_basic_info.work_status[i].work_status_desc);
        result.work_status.push(data.user_basic_info.work_status[i].work_status_desc);
      }
      //console.log(workStatus);
      if (workStatus.includes("Part Time") || workStatus.includes("Part-Time")) {
        temp_type = "part_time";
        result.pt_hourly = hourly_rates["part_time"][currency]["hourly_rate"];
        result.pt_monthly = hourly_rates["part_time"][currency]["monthly_rate"];
      }
      else{
        result.ft_hourly = hourly_rates["full_time"][currency]["hourly_rate"];
        result.ft_monthly = hourly_rates["full_time"][currency]["monthly_rate"];
      }
      result.staff_hourly_rates =
        hourly_rates[temp_type][currency]["hourly_rate"];
      result.staff_monthly = hourly_rates[temp_type][currency]["monthly_rate"];
      result.work_type = type;
    }
  }
 
  return result;
}

function displayAvailabitlity(data) {
  let result = false;
  let array = data;
  //work.work_status_desc
  if (data.length == 0) {
    result = false;
  } else {
    for (var i = 0; i < array.length; i++) {
      if (array[i].work_status_desc != "") {
        result = true;
        break;
      }
    }
  }

  return result;
}

function displaySpeedTestLink(data) {
  if (data == null) {
    return false;
  }
  let result = data.search("http");
  return result;
}

function getDate(data) {
  let dateString = data;
  let string = dateString.split(" ");
  let date = new Date(string[0]);

  let month = date.toLocaleString("default", { month: "long" });
  let year = date.getFullYear();
  return month + " " + year;
}

function getInitialSkills(data) {
  let array = [];
  if (data.length < 4) {
    for (var i = 0; i < data.length; i++) {
      if (data[i] != "") {
        array.push(data[i]);
      }
    }
  } else {
    for (var j = 0; j < 4; j++) {
      if (data[j] != "") {
        array.push(data[j]);
      }
    }
  }
  return array;
  //console.log(skills.advanced);
}

function getFinalWorkArray(data){
  console.log(data);
  let array = [];
  let tempArray = [];
  let tempMark = -1;
  tempArray = data.sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
  console.log(tempArray);
  tempArray.forEach((history,index) => {
    console.log(index);
    if((history.present_work == 'Yes') || (history.present_work == 'yes')){
      //history.end_date
      array.push(history);
      tempMark = index;
      return;
    }
    
  });


  tempArray.forEach((history,index) => {
    if(index != tempMark){
      //history.end_date
      array.push(history);
      //tempMark = index;
      //return;
    }
  });
  //console.log(array);
  //console.log(tempMark);
  return array;
}

function getInitialSpecialty(data) {
  let array = [];
  if (data.length < 3) {
    for (var i = 0; i < data.length; i++) {
      if (data[i] != "") {
        array.push(data[i]);
      }
    }
  } else {
    for (var j = 0; j < 3; j++) {
      if (data[j] != "") {
        array.push(data[j]);
      }
    }
  }
  return array;
}

function displayModal(){
  modalDisplay.value = true;
  console.log(modalDisplay.value);
}

function getInitialCategory(data) {
  let array = [];
  if (data.length != 0) {
    array.push(data[0]);
  }
  return array;
}

 async function copyLink() {
   let text = window.location.href;
   try {
     showTooltip.data = true;
     await navigator.clipboard.writeText(text);
     message.data = "Copied Link";
     console.log('Content copied to clipboard');
     /* Resolved - text copied to clipboard successfully */
   } catch (err) {
     console.error("Failed to copy: ", err);
     /* Rejected - text failed to copy to the clipboard */
   }
 }
</script>

<template>
  <TopMenu></TopMenu>
  <!-- <aside>
        <div class="rcb modal">
            <div class="modal-background"></div>
            <div class="modal-content is-fullheight">   
                <div class="columns is-gapless">
                    <div class="column" id="rcb-left">
                        <button class="delete close-btn has-text-grey-light" aria-label="close"></button>
                        <h2 class="text-center">Request A Call Back</h2>

                        <p>Drive sustainable quality &amp; growth while saving 70% of
                        your staffing cost.</p>

                        <p>Talk to us and see how Filipino remote staff can
                        transform your business.</p>

                        <ul class="green-check">
                            <li>Unrivalled expertise; we have been recruiting Filipino remote workers for Australian SMEs since 2007. </li>

                            <li>Interview pre-qualified, tested and experienced Filipino Remote Workers.</li>

                            <li>Work with an Australian Company with an all-Filipino team. </li>

                            <li>Work Monitoring Software that allows transparency and trust.</li> 

                            <li>We take care of your Filipino staff and Philippine compliance.</li>  

                            <li>Dedicated Lead Account Officer.</li>
                        </ul>
                    </div>
                    <div class="column" id="rcb-right">
                        <button class="delete close-btn has-text-grey-light" aria-label="close"></button>
                        <iframe class="rcb-form" src="https://www.remotestaff.com.au/new-rcb-form/index.html" frameborder="0" width="100%" height="670" scrolling="no"></iframe>
                    </div>
                </div>
            </div>
        </div>
        <div class="ph-prompt modal">
            <div class="modal-background"></div>
            <div id="ph-prompt-content">
                <div class="modal-content">
                    <img src="https://d187d1gj7bx34n.cloudfront.net/wp-content/uploads/2021/02/exclamation.png" style="text-align:center;margin-top:-45px"/>
                    <button class="delete close-btn has-text-grey-light is-pulled-right" aria-label="close"></button>
                    <h3 class="">Howdy!</h3>
                    <p>We have noticed that you're in the Philippines.</p>
                    <p>If you're an applicant please register at<br/><a href="https://www.remotestaff.ph/">RemoteStaff.ph</a></p>
                    <p>Cheers!</p>
                    <button class="continue-btn">Continue To Request A Call Back</button>
                </div>
            </div>
        </div>
    </aside> -->

  <LoaderBar v-if="!hasData"></LoaderBar>

  <div class="modal"  :class="{'is-active':modalDisplay}">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title"></p>
        <button class="delete" aria-label="close" @click="modalDisplay = !modalDisplay"></button>
      </header>
      <section class="modal-card-body">
        <!-- Content ... -->
        <iframe v-if="asl_candidate.info.show_interview_vid &&
                    asl_candidate.info.interview_vid_url && modalDisplay" :src="asl_candidate.info.interview_vid_url" 
        allow="camera *; microphone *; autoplay *; encrypted-media *; 
        fullscreen *; display-capture *;" class="video-container"
        style="border: none; border-radius: 24px">
        </iframe>
      </section>
      <!--<footer class="modal-card-foot">
        <button class="button is-success">Save changes</button>
        <button class="button">Cancel</button>
      </footer>-->
    </div>
  </div>

  <section v-if="hasData">
    <div class="resume is-active">
      <div class="container is-fullheight">
        <div class="content has-background-white">
          <div class="columns is-variable is-2 is-centered">
            <div class="column is-4 pic-container">
              <div
                v-if="profileHeader.isDisplayPicture"
                class="resume-border-pic circle-picture"
              >
                <img :src="profileHeader.profilePicLink" />
              </div>
              <div v-else class="default-picture circle-picture"></div>
              <div class="candidate-number is-size-5 is-size-6-mobile">
                <b>Candidate No. {{ asl_candidate.info.bullhorn_id }}</b>

                <div
                  class="share-button-container"
                  v-if="
                    asl_candidate.info.show_interview_vid &&
                    asl_candidate.info.interview_vid_url
                  "
                >
                  <div class="is-size-6 is-size-6-mobile">
                    <!--<a
                      :href="asl_candidate.info.interview_vid_url"
                      target="_blank"
                    >-->
                      
                        <button class="button rs-dark-btn watch-interview" @click="displayModal">
                          <span
                            class="fa fa-video-camera"
                            style="color: #62ff58"
                            
                          ></span
                          >&nbsp;&nbsp;<b>Watch Interview</b>
                        </button>
                     
                    <!--</a>-->
                  </div>
                </div>
                <!-- ShareLInk -->
                <div class="share-button-container">
                  <div class="is-size-6 is-size-6-mobile" v-tooltip="{
                        global: true,
                        theme: {
                          placement: 'top',
                          width: 'fit-content',
                          color: '#ffffff',
                          border: '1px solid #ccc',
                          'background-color': '#333',
                          padding: '.5rem',
                          show : false
                        },
                        displayArrow:true,
                      }">
                    <b>
                      <button v-if="showTooltip.data"
                        class="button rs-dark-btn tooltip-link has-tooltip-right"
                        @click="copyLink"
                        v-tooltip="`${message.data}`"
                      >
                        <span
                          class="fa fa-share"
                          style="color: #62ff58"
                          
                        ></span
                        >&nbsp;&nbsp;Share Link
                      </button>

                      <button v-else
                        class="button rs-dark-btn tooltip-link has-tooltip-right"
                        @click="copyLink"
                        
                      >
                        <span
                          class="fa fa-share"
                          style="color: #62ff58"
                          
                        ></span
                        >&nbsp;&nbsp;Share Link
                      </button>
                    </b>
                  </div>
                </div> 
                <!--<ShareLink></ShareLink>-->
              </div>
              <div class="audio-container">
                <div v-if="profileHeader.isDisplayVoice">
                  <div>PLAY AUDIO</div>
                  <div class="staff-audio">
                    <audio
                      preload="none"
                      class="audio width-100"
                      controls
                      controlsList="nodownload"
                      aria-controls
                    >
                      <source
                        :src="profileHeader.voiceRecordingLink"
                        type="audio/mpeg"
                      />
                      <!--<source src="https://portal.remotestaff.com.au/available-staff-resume/get-attachment/voice/{{ doc.bullhorn_id }}" type="audio/ogg">-->
                      Your browser does not support the
                      <code>audio</code> element.
                    </audio>
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-auto row-1" style="z-index: 30">
              <div class="tile is-ancestor">
                <div class="tile is-vertical">
                  <div
                    class="tile is-parent category-container"
                    id="category-container"
                  >
                    <div class="tile is-child">
                      <div
                        class="name is-size-3 has-text-weight-bold"
                        style="color: white !important"
                      >
                        {{ asl_candidate.info.user_basic_info.firstname }}
                      </div>
                      <div class="category-and-skills">
                        <p
                          class="is-size-5 profile-specialty"
                          v-for="category in getInitialCategory(
                            asl_candidate.info.user_basic_info.category
                          )"
                          :key="category.id"
                        >
                          {{ category.category_desc }}
                        </p>

                        <p
                          class="is-size-5 profile-specialty"
                          v-for="specialty in getInitialSpecialty(
                            asl_candidate.info.user_basic_info.specialty
                          )"
                          :key="specialty.id"
                        >
                          {{ specialty.specialty_desc }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="tile reverse-method">
                    <div class="tile is-5 is-parent is-vertical">
                      <div class="is-size-4 has-text-weight-bold">Skills</div>
                      <div class="skill-container">
                        <p>
                          <span
                            class="is-size-5 has-text-weight-bold skill-star"
                            >★★★
                          </span>
                          <span class="is-4 has-text-weight-bold"
                            >Advanced:</span
                          >
                        </p>
                        <p v-if="skills.advanced.length > 0">
                          <span
                            v-for="(n, index) in getInitialSkills(
                              skills.advanced
                            )"
                            :key="index"
                            >{{ n }},
                          </span>
                          <span
                            ><a href="#skills-section"
                              ><strong>more</strong></a
                            ></span
                          >
                        </p>
                      </div>
                      <div class="skill-container">
                        <p>
                          <span
                            class="is-size-5 has-text-weight-bold skill-star"
                            >★★
                          </span>
                          <span class="is-4 has-text-weight-bold"
                            >Intermediate:</span
                          >
                        </p>
                        <p v-if="skills.intermediate.length > 0">
                          <span
                            v-for="(n, index) in getInitialSkills(
                              skills.intermediate
                            )"
                            :key="index"
                            >{{ n }},
                          </span>
                          <span
                            ><a href="#skills-section"
                              ><strong>more</strong></a
                            ></span
                          >
                        </p>
                      </div>
                    </div>
                    <div
                      class="hourly-rate-container tile is-verical is-parent"
                    >
                      <gauge
                        v-if="asl_candidate.info.hourly_rates"
                        :records="asl_candidate.info"
                        :tempCurrency="currency"
                        :tempSymbol="symbol"
                      ></gauge>
                    </div>
                  </div>
                  <div class="note-container tile" style="display: contents">
                    <p class="note">
                      *includes service fee. Please note that hourly rates are
                      linked to the exchange rate, and fluctuate slightly from
                      month to month.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="resume-body">
            <div
              class="resume-subcategory-container"
              v-if="asl_candidate.info.user_basic_info.work_status"
            >
              <div class="resume-subcategory-title has-text-weight-bold">
                Employment Preferences
              </div>
              <div class="resume-subcategory-content">
                <div label="Employment Preferences">
                  <div class="resume-subcategory-details columns is-multiline">
                    <div v-html="resumeText"></div>
                    <p class="column is-4">Availability:</p>
                    <div
                      class="column is-8"
                      v-if="
                        displayAvailabitlity(
                          asl_candidate.info.user_basic_info.work_status
                        )
                      "
                    >
                      <div>
                        <span
                          v-for="work in asl_candidate.info.user_basic_info
                            .work_status"
                          :key="work.id"
                        >
                          <span
                            class="is-link is-size-6 ft-tag"
                            v-if="work.work_status_desc == 'Full Time' || work.work_status_desc == 'Full-Time'"
                          >
                            <i class="fas fa-clock-o"></i>&nbsp;{{
                              work.work_status_desc
                            }}
                          </span>
                          <span
                            class="is-link is-size-6 pt-tag"
                            v-else-if="work.work_status_desc == 'Part Time' || work.work_status_desc == 'Part-Time'"
                          >
                            <i class="fas fa-clock-o"></i>&nbsp;{{
                              work.work_status_desc
                            }}
                          </span>
                        </span>
                      </div>
                    </div>
                    <p class="column is-8" v-else><i>Not Applicable</i></p>
                    <p class="column is-4">Preferred Timezone:</p>
                    <p
                      class="column is-8"
                      v-if="
                        asl_candidate.info.user_basic_info.timezone.length > 0
                      "
                    >
                      <span
                        v-for="(time, index) in asl_candidate.info
                          .user_basic_info.timezone"
                        :key="time.id"
                      >
                        <span
                          v-if="
                            index <
                            asl_candidate.info.user_basic_info.timezone.length -
                              1
                          "
                          >{{ time.timezone_desc }},
                        </span>
                        <span v-else>{{ time.timezone_desc }}</span>
                      </span>
                    </p>
                    <p class="column is-8" v-else><i>Not Applicable</i></p>

                    <p class="column is-4">
                      Hourly & Monthly Rate:<br />(inclusive of service fee)
                    </p>
                    <div
                      class="column is-8"
                      v-if="
                        asl_candidate.info.user_basic_info.work_status.length >
                        0
                      "
                    >
                      <!--getRates(asl_candidate.info)
                                            let result = {
                                                pt_hourly:'',
                                                ft_hourly:'',
                                                pt_monthly:'',
                                                ft_monthly:'',
                                                staff_monthly:'',
                                                work_type:'',
                                            };-->
                      <span v-if='getRates(asl_candidate.info).work_status.includes("Part Time") || getRates(asl_candidate.info).work_status.includes("Part-Time")'
                        >Part Time:
                        {{
                          currency.toUpperCase() +
                          " " +
                          symbol +
                          getRates(asl_candidate.info).pt_hourly
                        }}
                        or per hour or
                        {{ symbol + getRates(asl_candidate.info).pt_monthly }}
                        per month</span
                      ><br />
                      <span
                      v-if='getRates(asl_candidate.info).work_status.includes("Full Time") || getRates(asl_candidate.info).work_status.includes("Full-Time")'>Full Time:
                        {{
                          currency.toUpperCase() +
                          " " +
                          symbol +
                          getRates(asl_candidate.info).ft_hourly
                        }}
                        or per hour or
                        {{ symbol + getRates(asl_candidate.info).ft_monthly }}
                        per month</span
                      >
                    </div>
                    <div class="column is-8" v-else>
                      <!--<span
                        >{{ getRates(asl_candidate.info).work_type }}:
                        {{
                          currency.toUpperCase() +
                          " " +
                          symbol +
                          getRates(asl_candidate.info).staff_hourly_rates
                        }}
                        per hour or
                        {{
                          symbol + getRates(asl_candidate.info).staff_monthly
                        }}
                        per month</span
                        
                      >-->Not Applicable<br />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="resume-subcategory-container">
              <div class="resume-subcategory-title has-text-weight-bold">
                Remote Staff Recruiter Comments
              </div>
              <div class="resume-subcategory-content">
                <div
                  class="column recruiters-comment-details"
                  v-if="asl_candidate.info.user_basic_info.note.length > 0"
                >
                  <div
                    
                  >
                    <div v-html="asl_candidate.info.user_basic_info.note[0].note"></div>
                  </div>
                </div>

                <div v-else class="column recruiters-comment-details">
                  <i>Not Applicable</i>
                </div>
              </div>
            </div>

            <div
              class="resume-subcategory-container"
              v-if="asl_candidate.info.user_basic_info.work_history.length != 0"
            >
              <div class="resume-subcategory-title has-text-weight-bold">
                Employment History
              </div>
              <div class="resume-subcategory-content">
                <div
                  class="employment history-head"
                  v-for="history in getFinalWorkArray(asl_candidate.info.user_basic_info.work_history)
                  "
                  :key="history.id"
                >
                  <div class="employment-title">
                    {{ history.position_held }}
                  </div>
                  <div class="employment history-content">
                    <div :label="history.position_held">
                      <div class="employment-details columns is-multiline">
                        <p class="column is-4">Industry:</p>
                        <p class="column is-8" v-if="history.industry_desc">
                          {{ history.industry_desc }}
                        </p>
                        <p class="column is-8" v-else><i>Not Applicable</i></p>
                        <p class="column is-4">Employment Period:</p>
                        <p
                          class="column is-8"
                          v-if="history.start_date && history.end_date && history.present_work == 'Yes'"
                        >
                          {{ getDate(history.start_date) }} -
                          Present
                        </p>
                        <p
                          class="column is-8"
                          v-else-if="history.start_date && history.end_date"
                        >
                          {{ getDate(history.start_date) }} -
                          {{ getDate(history.end_date) }}
                        </p>
                        <p class="column is-8" v-else><i>Not Applicable</i></p>

                        <p class="column is-4">Duties and Responsibilities:</p>
                        <div class="column is-8" v-if="history.duties_and_resp">
                          <div v-html="history.duties_and_resp"></div>
                        </div>
                        <p class="column is-8" v-else><i>Not Applicable</i></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="resume-subcategory-container">
              <div class="resume-subcategory-title has-text-weight-bold">
                Basic Information
              </div>
              <div class="resume-subcategory-content">
                <div label="Basic Information">
                  <div class="columns is-multiline">
                    <!--<p class="column is-4">Age:</p>
                    <p
                      class="column is-8"
                      v-if="
                        asl_candidate.info.user_basic_info.birthdate != '' &&
                        age > 17
                      "
                    >
                      {{ age }}
                    </p>
                    <p class="column is-8" v-else><i>Not Applicable</i></p>-->
                    <p class="column is-4">Nationality:</p>
                    <p
                      class="column is-8"
                      v-if="
                        asl_candidate.info.user_basic_info.nationality != ''
                      "
                    >
                      {{ asl_candidate.info.user_basic_info.nationality }}
                    </p>
                    <p class="column is-8" v-else><i>Not Applicable</i></p>
                    <!--<p class="column is-4">Gender:</p>
                    <p
                      class="column is-8"
                      v-if="asl_candidate.info.user_basic_info.gender != ''"
                    >
                      {{ asl_candidate.info.user_basic_info.gender }}
                    </p>
                    <p class="column is-8" v-else><i>Not Applicable</i></p>-->
                    <!--<p class="column is-4">English Proficiency Exam:</p>
                                        <p class="column is-8"><span class="has-text-success fa fa-check">&nbsp;</span><span>doc.language</span></p>-->
                  </div>
                </div>
              </div>
            </div>

            <div
              class="resume-subcategory-container"
              v-if="asl_candidate.info.user_basic_info.educ_attain.length != 0"
            >
              <div class="resume-subcategory-title has-text-weight-bold">
                Education History
              </div>
              <div class="resume-subcategory-content">
                <div
                  class="history-border"
                  label="Education History"
                  v-for="educ in asl_candidate.info.user_basic_info.educ_attain.sort(
                    (a, b) => new Date(b.graddate) - new Date(a.graddate)
                  )"
                  :key="educ.id"
                >
                  <div class="columns is-multiline mb-2">
                    <p class="column is-4">Field of Study:</p>
                    <p class="column is-8" v-if="educ.field_of_study_desc">
                      {{ educ.field_of_study_desc }}
                    </p>
                    <p class="column is-8" v-else><i>Not Applicable</i></p>
                    <p class="column is-4">Major:</p>
                    <p class="column is-8" v-if="educ.major">
                      {{ educ.major }}
                    </p>
                    <p class="column is-8" v-else><i>Not Applicable</i></p>
                    <p class="column is-4">Graduation Date:</p>
                    <p class="column is-8" v-if="educ.graddate">
                      {{ getDate(educ.graddate) }}
                    </p>
                    <p class="column is-8" v-else><i>Not Applicable</i></p>
                    <p class="column is-4">Located In:</p>
                    <p class="column is-8" v-if="educ.institute">
                      {{ educ.institute }}
                    </p>
                    <p class="column is-8" v-else><i>Not Applicable</i></p>
                    <p class="column is-4">License and Certification:</p>
                    <div
                      class="column is-8"
                      v-if="educ.licensecert"
                      v-html="educ.licensecert"
                    ></div>
                    <p class="column is-8" v-else><i>Not Applicable</i></p>
                  </div>
                </div>
              </div>
            </div>

            <div class="resume-subcategory-container" id="skills-section">
              <div class="resume-subcategory-title has-text-weight-bold">
                Skills
              </div>
              <div class="resume-subcategory-content">
                <div label="Skills">
                  <div
                    class="columns is-multiline"
                    style="padding: 0rem 1rem 1rem 0rem"
                  >
                    <div class="column is-6">
                      <div>
                        <span class="is-size-5 has-text-weight-bold">★★★ </span>
                        <span class="is-4 has-text-weight-bold">Advanced:</span>
                        <br />
                        <div v-if="skills.advanced.length > 0">
                          <span
                            v-for="(skill, index) in skills.advanced"
                            :key="index"
                          >
                            <span v-if="index < skills.advanced.length - 1"
                              >{{ skill }},
                            </span>
                            <span v-else>{{ skill }}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="column is-6">
                      <div>
                        <span class="is-size-5 has-text-weight-bold">★★ </span>
                        <span class="is-4 has-text-weight-bold"
                          >Intermediate:</span
                        >
                        <br />
                        <div v-if="skills.intermediate.length > 0">
                          <span
                            v-for="(skill, index) in skills.intermediate"
                            :key="index"
                          >
                            <span v-if="index < skills.intermediate.length - 1"
                              >{{ skill }},
                            </span>
                            <span v-else>{{ skill }}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="resume-subcategory-container"
              v-if="asl_candidate.info.user_basic_info.wfh_resource"
            >
              <div class="resume-subcategory-title has-text-weight-bold">
                Work at Home Capabilities
              </div>
              <div class="resume-subcategory-content">
                <div label="Work at Home Capabilities">
                  <div class="columns is-multiline">
                    <p class="column is-4">Internet Bandwidth:</p>
                    <p
                      class="column is-8"
                      v-if="
                        asl_candidate.info.user_basic_info.wfh_resource
                          .plan_bandwidth
                      "
                    >
                      {{
                        asl_candidate.info.user_basic_info.wfh_resource
                          .plan_bandwidth
                      }}
                    </p>
                    <p class="column is-8" v-else><i>Not Applicable</i></p>
                    <p class="column is-4">Working Environment:</p>
                    <p
                      class="column is-8"
                      v-if="
                        asl_candidate.info.user_basic_info.wfh_resource.work_env
                      "
                    >
                      {{
                        asl_candidate.info.user_basic_info.wfh_resource.work_env
                      }}
                    </p>
                    <p class="column is-8" v-else><i>Not Applicable</i></p>
                    <p class="column is-4">Speed Test Result:</p>
                    <div class="column is-8 speed_test_div">
                      <div
                        v-if="
                          displaySpeedTestLink(
                            asl_candidate.info.user_basic_info.wfh_resource
                              .speedtest_url
                          )
                        "
                      >
                        <a
                          :href="
                            asl_candidate.info.user_basic_info.wfh_resource
                              .speedtest_url
                          "
                          target="_blank"
                        >
                          {{
                            asl_candidate.info.user_basic_info.wfh_resource
                              .speedtest_url
                          }}</a
                        >
                      </div>

                      <div v-else>
                        {{
                          asl_candidate.info.user_basic_info.wfh_resource
                            .speedtest_url
                        }}
                      </div>
                    </div>
                    <p class="column is-4">Internet Type:</p>
                    <p
                      class="column is-8"
                      v-if="
                        asl_candidate.info.user_basic_info.wfh_resource
                          .internet_type_desc != ''
                      "
                    >
                      {{
                        asl_candidate.info.user_basic_info.wfh_resource
                          .internet_type_desc
                      }}
                    </p>
                    <p class="column is-8" v-else><i>Not Applicable</i></p>
                    <p class="column is-4">Hardware Type:</p>
                    <p
                      class="column is-8"
                      v-if="
                        asl_candidate.info.user_basic_info.wfh_resource
                          .hardware_type_desc != ''
                      "
                    >
                      {{
                        asl_candidate.info.user_basic_info.wfh_resource
                          .hardware_type_desc
                      }}
                    </p>
                    <p class="column is-8" v-else><i>Not Applicable</i></p>
                    <p class="column is-4">Brand Name:</p>
                    <p
                      class="column is-8"
                      v-if="
                        asl_candidate.info.user_basic_info.wfh_resource
                          .comp_brandname != ''
                      "
                    >
                      {{
                        asl_candidate.info.user_basic_info.wfh_resource
                          .comp_brandname
                      }}
                    </p>
                    <p class="column is-8" v-else><i>Not Applicable</i></p>
                    <p class="column is-4">Processor:</p>
                    <p
                      class="column is-8"
                      v-if="
                        asl_candidate.info.user_basic_info.wfh_resource
                          .comp_processor != ''
                      "
                    >
                      {{
                        asl_candidate.info.user_basic_info.wfh_resource
                          .comp_processor
                      }}
                    </p>
                    <p class="column is-8" v-else><i>Not Applicable</i></p>
                    <!--<p class="column is-4">RAM:</p>
                                        <p class="column is-8">work_home_capabilities.ram </p>-->
                    <p class="column is-4">Operating System:</p>
                    <p
                      class="column is-8"
                      v-if="
                        asl_candidate.info.user_basic_info.wfh_resource
                          .comp_os != ''
                      "
                    >
                      {{
                        asl_candidate.info.user_basic_info.wfh_resource.comp_os
                      }}
                    </p>
                    <p class="column is-8" v-else><i>Not Applicable</i></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2">
      &nbsp;
    </div>
  </section>
</template>

<style rel="stylesheet" src="../../assets/css/lvr-styles.css"></style>
<!--<style rel="stylesheet" src="../../assets/css/bulma.min.css"></style>
<style rel="stylesheet" src="../../assets/css/bulma.min.css"></style>-->
<!-- <style scoped src="../../assets/css/font-awesome.min.css">
</style> -->

<!--<style scoped rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bulma@0.9.2/css/bulma.min.css">
</style>
<style scoped rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bulma-tooltip@3.0.2/dist/css/bulma-tooltip.min.css">
</style>
<style scoped rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
</style>-->
