import { defineStore } from "pinia";
import { checkIp, getCandidate, getS3Bucket } from "@/api";

export const useMainStore = defineStore("main", {
  state: () => ({
    message: "Hello World",
    ipData: null,
    currency: "",
    symbol: "",
    bullhorn_id: null,
    candidateData: null
  }),
  getters: {
    getMessage: (state) => state.message,
    getAge(state) {
      if (state.candidateData.user_basic_info.birthdate) {
        const dateString = state.candidateData.user_basic_info.birthdate;
        let today = new Date();
        let birthDate = new Date(dateString);
        let age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        return age;
      }
    },
    getPicture(state) {
      let picture_link = getS3Bucket();
      let pictureObject = {
        picture_link:'',
        isDisplayPicture:false,
      };
      let array = state.candidateData.user_basic_info.files;
      if(state.candidateData.user_basic_info.files){
        for (var i = 0; i < array.length; i++) {
          if (array[i].filetype_desc == "Profile Picture") {
            pictureObject.picture_link = picture_link + array[i].path;
            pictureObject.isDisplayPicture = true;
            break;
          }
        }
      }
      
      return pictureObject;
    },
    getVoiceRecording(state) {
      let voice_link = getS3Bucket();
      let voiceObject = {
        voice_link:'',
        isDisplayVoice:false,
      };
      let array = state.candidateData.user_basic_info.files;
      //let display = false;
      if(state.candidateData.user_basic_info.files){
        for (var i = 0; i < array.length; i++) {
          if (array[i].filetype_desc == "Voice Record") {
            voiceObject.voice_link = voice_link + array[i].path;
            voiceObject.isDisplayVoice = true;
            break;
          }
        }
      }
      
      return voiceObject;
    },
    getSkills(state) {
      //console.log(data);
      let array = state.candidateData;
      let skills = {
        intermediate:[],
        advanced:[],
      }
      //console.log(array);
      for (var i = 0; i < array.intermediate_skills.length; i++) {
        if (array.intermediate_skills[i] != "") {
          skills.intermediate.push(array.intermediate_skills[i]);
        }
      }
      for (var j = 0; j < array.advanced_skills.length; j++) {
        if (array.advanced_skills[j] != "") {
          skills.advanced.push(array.advanced_skills[j]);
        }
      }
      return skills;
      //console.log(skills);
    }
  },
  actions: {
    setMessage(msg) {
      this.message = msg;
    },
    async getCandidate(bullhorn_id) {
      const result = await getCandidate(bullhorn_id);
      if (result && result.success) {
        // console.log("bullhorn_id", result.message.bullhorn_id);
        this.candidateData = result.message;
      }
    },
    async checkIp() {
      // console.log("Checking IP Address");
      const result = await checkIp();
      // console.log("result", result);
      if (result && result.iso_code) {
        this.ipData = result;
        const iso_code = result.iso_code;
        switch (iso_code) {
          case "CA":
            this.currency = "cad";
            this.symbol = "$";
            break;
          case "GB":
            this.currency = "gbp";
            this.symbol = "£";
            break;
          case "NZ":
            this.currency = "nzd";
            this.symbol = "$";
            break;
          case "US":
            this.currency = "usd";
            this.symbol = "$";
            break;
          default:
            this.currency = "aud";
            this.symbol = "$";
        }
      }
    }
  }
});
