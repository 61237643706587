<script setup>
    import arrow from '@/assets/img/asl-images/arrow.png';
    import { defineProps } from 'vue';

    const props = defineProps({ records:{},tempCurrency:String,tempSymbol:String});
    //const { no } = toRefs(props);
    
    let work_status = [];
    let currency = 'aud';
    let hourly_rates = {};
    //let hourly_rates = data.hourly_rates.v2.employee_types;
    let symbol = "$";
    let availability = "full_time";
    let rates ;
    let pricing_guide;
    let last;
    let first;
    let closest;
    let hr_list = [];
    const degree_list = [-30, -11.54, 6.92, 24.38, 43.84, 62.3, 81.76, 99.92, 117.68, 136.14, 154.6, 173.06, 191.52, 209.96];
    let closest_index;
    let degree;
    let angle;
    let u_currency;
    let staff_hourly_rates;
    let data = {
    };
    

    const init =()=>{
        data = props.records;
        currency = props.tempCurrency;
        symbol = props.tempSymbol;
        //(props.records);
        hourly_rates = data.hourly_rates.v2.employee_types;
        //console.log(data.user_basic_info.work_status);
        //console.log(data.hourly_rates);
        for(var i = 0 ; i < data.user_basic_info.work_status.length;i++){
            work_status.push(data.user_basic_info.work_status[i].work_status_desc);
        }

        availability = "full_time";
        if (work_status.includes('Part Time')) {
            availability = "part_time";
        }
        //console.log(work_status);
        //console.log(availability);
        //console.log(availability);
        //console.log(work_status);
        rates = hourly_rates[availability][currency];
        pricing_guide = rates['pricing_guide'];

        //console.log(rates);
        //console.log(pricing_guide);
        last = Object.keys(pricing_guide)[Object.keys(pricing_guide).length - 1];
        first = Object.keys(pricing_guide)[0];

        //console.log(last);
        //console.log(first);

        hr_list = [];
        Object.keys(pricing_guide).map((key) => {
            return hr_list.push(pricing_guide[key])
        });

        closest = hr_list.reduce((a, b) => {
        let aDiff = Math.abs(a - rates.hourly_rate);
        let bDiff = Math.abs(b - rates.hourly_rate);

        if (aDiff === bDiff) {
                // Choose largest vs smallest (> vs <)
                return a > b ? a : b;
            } else {
                return bDiff < aDiff ? b : a;
            }
        });

        

        closest_index = hr_list.indexOf(closest)
        degree = degree_list[closest_index];


        angle = `${degree}deg`;

        u_currency = currency.toUpperCase();
        staff_hourly_rates = rates.hourly_rate;
    }

    init();
    
    

    //let rates = hourly_rates[availability][currency];
    //const pricing_guide = rates['pricing_guide'];
    
</script>
<template>
    <div class="tile is-child pricing-container">
        <div class="hourly-rate-text"><p>Hourly Rate Gauge</p></div>
        <div id="todays-value">
            <p>
                <span>
                    Today's value&nbsp;
                </span>
                <span class="pricing">
                    {{u_currency +symbol+" "+staff_hourly_rates}}
                </span>
            </p>
        </div>
        <div id='pricing' >
            <img :src='arrow' style='width:34%; margin-top:12%; position:absolute; ' :style="{ transform: 'rotate('+angle+')'}" alt='boks pogi'/>
        </div>
        <div id="pricing-guide">
            <div class='pricing-range'>
                <p><strong>{{symbol + hr_list[0]}}</strong></p>if {{symbol}}1 = PHP {{first}}
            </div>
            <div class='pricing-range'>
                <p><strong>{{symbol+hr_list[hr_list.length-1]}}</strong></p>if{{symbol}} 1 = PHP {{last}}
            </div>
        </div>
    </div>
    
</template>
<style scoped src="../assets/css/lvr-styles.css">
    .resume-border-pic{
        background-image: url('/src/assets/img/asl-images/resume_border.png');
        /*background-image: url('../../assets/img/asl-images/resume_border.png')!important;*/
    }
    .default-picture{
        background-image: url('/src/assets/img/asl-images/banner-default.png');
        /*background-image: url('../../assets/img/asl-images/banner-default.png')!important;*/
    }
</style>