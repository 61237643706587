<template>
  <div class="section">
    <div class="container">
      <div class="columns">
        <div class="column is-6 is-offset-3">
          <div class="">
            Loading Staff Resume ...
            <progress class="progress is-small is-info" max="100"></progress>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
