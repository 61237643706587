<script setup>
  const init = () =>{
    window.location.href = `https://www.remotestaff.com.au/candidate-unvailable/`;
  }
  init();
</script>
<template>
  <div class="home">
    <!--<img alt="Vue logo" src="../assets/logo.png">-->
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div>
</template>


<!--<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  }
}
</script>-->
